import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';

import { buildUnassignUserFromCollarUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';
import { DrillholeAssignedUser } from '../types';

interface UnassignResponse {
    Result: Record<string, DrillholeAssignedUser>;
}
export const unassignUserFromDrillhole = (
    drillHoleId: string,
    type: ACTIVITY_TYPES,
    activityGroups: string[] = [],
): Observable<Record<string, DrillholeAssignedUser>> => {
    const url = buildUnassignUserFromCollarUrl(drillHoleId, type);

    return sendRequestWithXSRFToken(
        url,
        {
            force_unassign: true,
            action: 'unassign',
            ...(activityGroups.length > 0 && { activity_groups: activityGroups }),
        },
        'PUT',
    ).pipe(
        switchMap(({ response }: AjaxResponse<UnassignResponse>) => {
            const { Result } = response;
            return observableOf(Result ?? {});
        }),
    );
};
